function showBodySpinner() {
    $('.spinner-container').fadeIn();
}
function hideBodySpinner() {
    $('.spinner-container').fadeOut();
}
function openModal(modal, data={}) {
    var elem = $(modal);
    if (data) {
        elem.data(data);
    }
    mr.modals.showModal(elem);
    trackEvent('Modals', 'Open', modal);
}
function clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}
function getURLParam(param) {
    var url = new URL(window.location.href);
    var c = url.searchParams.get(param);
    return c;
}
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        return decodeURI(parts.pop().split(";").shift());
    } else {
        return false;
    }
}
function openCallTypeform(fields={}) {
    openTypeform(window.typeform_call_id, fields, function () {
        window.location.href = '/' + (window.lang_code !== 'en' ? window.lang_code + '/' : '') + window.url_translations['schedule_call_page'][window.lang_code] + '?sid=' + fields['sid'] + '&entity=sales_call';
    });
}

function openInfoRequestTypeform(message) {
    const retryIntercom = (retries = 5, delay = 500) => {
        if (typeof Intercom === 'function') {
            hideBodySpinner();
            Intercom('showNewMessage', message || 'Salve! Vi contatto per richiedere informazioni.');
        } else if (retries > 0) {
            showBodySpinner();
            setTimeout(() => retryIntercom(retries - 1, delay), delay);
        } else {
            hideBodySpinner();
            console.error('Intercom is not ready after multiple attempts.');
        }
    };

    retryIntercom();
}

function openTypeform(id, fields = {}, onSubmit = '') {
    var analytics_present = typeof window.analytics === 'undefined' || typeof window.analytics.user === 'undefined';
    if (!("sid" in fields)) {
        if (analytics_present) {
            // console.log('case 1');
            var sid;
            if (getCookie('ajs_anonymous_id')) {
                sid = getCookie('ajs_anonymous_id');
                // console.log('sid taken from cookie', sid);
            } else {
                sid = generateSid();
                // console.log('sid generated', sid);
            }
            fields["sid"] = sid;
        } else {
            // Analytics present
            // console.log('case 2');
            fields["sid"] = analytics.user().anonymousId();
            fields = {...fields, ...analytics.user().traits()}
        }
    }
    if (!("page" in fields)) {
        fields["page"] = window.page_name;
    }
    fields["path"] = window.location.pathname;
    fields["if_local"] = window.location.host.startsWith('127');
    const tf = window.tf.createSlider(id, {
        position: 'left',
        hideHeaders: true,
        hideFooters: true,
        iframeProps: {
            "data-hj-allow-iframe": true
        },
        hidden: fields,
        onSubmit: ({formId, responseId}) => {
            console.log('submitted', formId, responseId);
            trackEvent('Typeform', 'Submit', id, fields);
            if (onSubmit !== '') {
                onSubmit();
            }
        }
    })
    tf.open();
    trackEvent('Typeform', 'Open', id, fields);
}
function generateSid() {
    var sid;
    if(!getCookie('ajs_anonymous_id')) {
        sid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    } else {
        sid = getCookie('ajs_anonymous_id');
    }
    console.log('generated custom sid', sid);
    return sid
}
// Don't remove even if it shows not used
function openVisaTypeform(fields = {}) {
    openTypeform(window.typeform_quote_id, fields, function (e) {
        if ('sid' in fields && fields['sid']) {
            var ident = identifyCallback(fields['sid'], 'visa_request', 'identify_customer', {});
        }
    });
}
function openEnVisaTypeform(fields) {
    analytics.ready(() => {
        fields["sid"] = analytics.user().anonymousId();
        fields = {...fields, ...analytics.user().traits()}
        openTypeform(window.typeform_quote_en_id, fields);
    });
}
var substringMatcherInd = function (strs) {
    return function findMatches(q, cb) {
        // Normalize query and matches to lower case
        const query = q.toLowerCase();

        // Filter the matches based on the query
        const matches = strs.filter(str => str.toLowerCase().includes(query));

        // Ensure exact matches or prefix matches are prioritized
        matches.sort((a, b) => {
            const aLower = a.toLowerCase();
            const bLower = b.toLowerCase();

            if (aLower === query) return -1;
            if (bLower === query) return 1;

            if (aLower.startsWith(query) && !bLower.startsWith(query)) return -1;
            if (!aLower.startsWith(query) && bLower.startsWith(query)) return 1;

            return aLower.localeCompare(bLower);
        });

        // Pass matches to Typeahead callback
        cb(matches);
    };
};

function initTypeahead(element, data, onSelectCallback, emptyText='Nessun Paese trovato') {
    element.typeahead({
        hint: false,
        highlight: true,
        minLength: 2
    }, {
        name: 'states',
        source: substringMatcherInd(data),
        templates: {
            empty: [`<div class="tt-suggestion tt-empty">${emptyText}</div>`]
        }
    });

    element.bind('typeahead:select', function (ev, selection) {
        onSelectCallback(selection);
    });
}
function displayLanguageBanner() {
    var preferredLanguages = navigator.languages || [navigator.language || navigator.userLanguage];

    // Loop through the preferred languages
    for (var i = 0; i < preferredLanguages.length; i++) {
        var preferredLanguage = preferredLanguages[i].toLowerCase();

        // Check if the preferred language exists in alternativeLanguages
        if (alternativeLanguages.hasOwnProperty(preferredLanguage)) {
            var languageData = alternativeLanguages[preferredLanguage];

            // Update the banner content
            $("#banner-text").text(languageData.text);
            $("#banner-cta").attr("href", languageData.cta_href);
            $("#banner-cta .btn__text").text(languageData.cta_text);
            $("#language-banner").slideDown(200);
            window.scrollY = 0;

            // Break the loop after the first match is found
            break;
        }
    }
}
function customScrollTo(element) {
    var offset = $(element).offset().top - 100;
    $('html, body').animate({
        scrollTop: offset
    }, 500);
}
$('.link-anchor').on('click', function (e) {
    e.preventDefault();
    var target = $(this).attr('href');
    customScrollTo(target);
});

$(function () {
    if (alternativeLanguages) {
        displayLanguageBanner();
        $('#banner-close').on('click', function () {
            var banner = $("#language-banner");
            if (banner) {
                $("#language-banner").slideUp(200);
            }
        });
    }
    $('#main-burger').on('click', function () {
        var nav = $('.nav-container');
        var icon = $('#main-burger i');
        icon.toggleClass('stack-up-open');
        icon.toggleClass('stack-menu');
        nav.toggleClass('menu-open');
        $('nav').slideToggle(200);
    });
    $('.typeahead').bind('typeahead:select', function (ev, suggestion) {
        $(ev.target).typeahead('val', '');
    });
});
function isEqual(obj1, obj2) {
  // Compare types
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Compare values
  if (typeof obj1 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!isEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  } else {
    return obj1 === obj2;
  }
}
function isAnalyticsPresent() {
    return (typeof window.analytics !== 'undefined' && typeof window.analytics.user !== 'undefined');
}
function isEveryConsentGiven() {
    var cookiePreferences = null;
    if (getCookie(`_iub_cs-${window.cookie_policy_id}`)) {
        var cookieContent = JSON.parse(decodeURIComponent(getCookie(`_iub_cs-${window.cookie_policy_id}`)));
        if ('purposes' in cookieContent) {
            cookiePreferences = JSON.parse(decodeURIComponent(getCookie(`_iub_cs-${window.cookie_policy_id}`)))['purposes'];
        }
    }
    if (!window.preferences || !isEqual(cookiePreferences, window.preferences)) {
        window.preferences = cookiePreferences;
    }
    for (let key in window.preferences) {
        if (window.preferences[key] !== true) {
            return false;
        }
    }
    return true;
}
function identifyCustomer(sid, entity) {
    const endpoint = 'identify_customer';
    const additionalArg = entity === 'sales_call' ? false : undefined;
    return identifyCallback(sid, entity, endpoint, {}, additionalArg);
}
function callScheduled(sid, event_url) {
    console.log('sid', sid)
    console.log('event_url', event_url)
    if (event_url.startsWith('https://api.calendly.com/scheduled_events/')) {
        var event_id = event_url.split('/').pop();
        var endpoint = 'call_scheduled';
        var data = {
            'sid': sid,
            'calendly_event_id': event_id
        }
        $.ajax({
            url: `${window.backend_url}/${endpoint}/`,
            type: 'POST',
            data: data,
            headers: {
                'Accept-Language': window.lang
            },
            tryCount: 0,
            retryLimit: 3,
            success: function (data) {
                return true
            },
            error: function (data) {
                if (data.status === 404) {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        setTimeout(() => {
                            $.ajax(this);
                        }, 500);
                    }
                }
                return false
            }
        });
    }
    return false
}
async function identifyCallback(sid, entity=null, endpoint, data={}, retry=true) {
    var identifyData = {};
    if (isEveryConsentGiven()) {
        if (!userIdentified()) {
            await $.ajax({
                url: `${window.backend_url}/${endpoint}/`,
                type: 'POST',
                data: {
                    sid: sid,
                    entity: entity
                },
                headers: {
                    'Accept-Language': window.lang
                },
                tryCount: 0,
                retryLimit: 3,
                success: function (data) {
                    var userData = data;

                    var lib_to_load = returnLibrariesToLoad(window.preferences, true);

                    if (window.preferences[2] === true && userData.user_hash && 'Intercom Web (Actions)' in lib_to_load) {
                        lib_to_load['Intercom'] = {
                            'user_hash': userData.user_hash
                        }
                    }

                    var userId = 'id' in userData ? userData.id : null;

                    identifyData = {
                        email: 'email' in userData ? userData.email : null,
                        name: 'owner_name' in userData ? userData.owner_name : null,
                        first_name: 'owner_name' in userData ? userData.owner_name : null,
                        // first_name: userData.owner_display.first_name,
                        // last_name: userData.owner_display.last_name,
                        phone: 'phone' in userData ? userData.phone : null,
                        country: 'country' in userData ? userData.country.name : null,
                        country_iso2: 'country' in userData ? userData.country.code : null,
                        nationality: 'nationality' in userData ? userData.nationality.name : null,
                        nationality_iso2: 'nationality' in userData ? userData.nationality.code : null,
                        language: window.lang,
                        user_hash: 'user_hash' in userData ? userData.user_hash : null,
                    }

                    console.log('userId', userId);
                    console.log('identifyData', identifyData);

                    identifyUser(userId, identifyData);

                },
                error: function (data) {
                    if (retry) {
                        if (data.status === 404) {
                            this.tryCount++;
                            if (this.tryCount <= this.retryLimit) {
                                setTimeout(() => {
                                    $.ajax(this);
                                }, 500);
                            }
                        }
                    }
                }
            });
        } else {
            identifyData = getTrait(null, true);
        }
    }
    return identifyData;
}
function initCalendly(url, parentElement, prefill = {}) {
    Calendly.initInlineWidget({
        url: url,
        parentElement: parentElement,
        prefill: prefill
    });
}
function getTrait(trait, if_all=False) {
    var traits;
    if (isAnalyticsPresent()) {
        traits = window.analytics.user().traits();
        return if_all ? traits : traits[trait];
    } else {
        if (window.localStorage.ajs_user_traits) {
            traits = JSON.parse(window.localStorage.ajs_user_traits);
            return if_all ? traits : traits[trait];
        }
    }
    return null;
}
function userIdentified() {
    if (isAnalyticsPresent()) {
        return window.analytics.user().id();
    } else if (window.localStorage.ajs_user_id) {
        return JSON.parse(window.localStorage.ajs_user_id);
    } else if (getCookie('ajs_user_id')) {
        return getCookie('ajs_user_id');
    } else {
        return false;
    }
}